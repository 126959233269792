import React from 'react';
import { css } from 'emotion';
import DropDown from 'react-dropdown';
import '../../assets/fonts/fonts.css';

// Components
import SliderModal from '../appComponents/SliderModal';
import PaymentRadio from '../appComponents/PaymentRadio';
import RentingChoiceButton from '../appComponents/RentingChoiceButton';
import Slider from '../appComponents/Slider';

/* global tw */
const Filter = (props) => {
    return (
        <SliderModal active={props.active} filter filterClick={props.filterClick}>
            <div className={css`${tw`relative flex flex-wrap items-center lg:px-12 px-6 py-4 pb-10 max-h-full w-full overflow-y-scroll`}; -webkit-overflow-scrolling: touch; `}>
                <div className={css`${tw`flex w-full justify-between items-center`}`}>
                    <h3> </h3>
                    <p className={css`${tw`cursor-pointer`}`} onClick={props.onCloseClick}>Close</p>
                </div>
                <div className={css`${tw`block`}`}>
                    <h3>Filter them by</h3>
                </div>
                <div className={css`${tw`flex w-full flex-wrap justify-between mb-12`}`}>
                    <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
                        <label className={css`${tw`block font-semibold text-grey-dark text-lg`}`}>Vehicle: </label>
                        <div>
                            <PaymentRadio
                                value='all'
                                onClick={props.vehicleAllClick}
                                active={props.vehicleAllActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        All
                        </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='MINI'
                                onClick={props.vehicleMiniCkick}
                                active={props.vehicleMiniActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`inline-block font-normal text-grey-dark text-lg`}`}>
                                        Mini
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='SEDAN'
                                onClick={props.vehicleSedanClick}
                                active={props.vehicleSedanActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Sedan
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='SUV'
                                onClick={props.vehicleSuvClick}
                                active={props.vehicleSuvActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        SUV
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                    </div>
                    {props.rentalChoiceTwo && <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
                        <label className={css`${tw`block font-semibold text-grey-dark text-lg`}`}>Fuel Type: </label>
                        <div>
                            <PaymentRadio
                                value='both'
                                onClick={props.fuelBothClick}
                                active={props.fuelBothActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Both
                        </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='Petrol'
                                onClick={props.fuelPetrolClick}
                                active={props.fuelPetrolActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Petrol
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='Diesel'
                                onClick={props.fuelDieselClick}
                                active={props.fuelDieselActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Diesel
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                    </div>}

                    {props.rentalChoiceTwo && <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
                        <label className={css`${tw`block font-semibold text-grey-dark text-lg`}`}>Transmission: </label>
                        <div>
                            <PaymentRadio
                                value='both'
                                onClick={props.transmissionBothClick}
                                active={props.transmissionBothActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Both
                            </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='Manual'
                                onClick={props.transmissionManualClick}
                                active={props.transmissionManualActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Manual
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='Automatic'
                                onClick={props.transmissionAutoClick}
                                active={props.transmissionAutoActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Automatic
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                    </div>}
                    {props.rentalChoiceTwo && <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
                        <label className={css`${tw`block font-semibold text-grey-dark text-lg`}`}>Kilometers: </label>
                        <div>
                            <PaymentRadio
                                value='both'
                                onClick={props.kilometersBothClick}
                                active={props.kilometersBothActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Both
                        </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='unlimited'
                                onClick={props.kilometersUnlimitedClick}
                                active={props.kilometersUnlimitedActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Unlimited
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                        <div>
                            <PaymentRadio
                                value='limited'
                                onClick={props.kilometersLimitedClick}
                                active={props.kilometersLimitedActive}>
                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                    <span className={css`${tw`block font-normal text-grey-dark text-lg`}`}>
                                        Limited
                                </span>
                                </div>

                            </PaymentRadio>
                        </div>
                    </div>}
                    {props.rentalChoiceTwo && <div className={css`${tw`block justify-around items-center content-around p-2 mb-4 lg:w-1/4 w-full`}`}>
                        <label className={css`${tw`block font-semibold text-grey-dark text-lg`}`}>Brands: </label>
                            <DropDown
                                options={props.brandsList}
                                value={props.brandLabel === 'clear' ? 'Select a Brand': props.brandLabel}
                                onChange={(event)=>{props.brandSelected(event);}}
                                placeholder="Select a Brand"
                                className={css`${tw`w-full mt-4`}`}
                            />
                        </div>}
                    <div className={css`${tw`w-full justify-center`}`}>
                    <label className={css`${tw`font-semibold text-grey-dark text-lg`}`}>Budget: </label>
                    <div className={css`${tw`px-4`}`}>
                    <Slider budgetValues={(event) => {props.budgetFilter(event)}} minAmt={props.minBudgetAmt} maxAmt={props.maxBudgetAmt} />
                    </div>
                    </div>
                </div>
            </div>
        </SliderModal>

    )
}

export default Filter;


// old code in cars list

{/* <SliderModal active={this.state.showRentingChoices} filter filterClick={this.handleFilter}>
<div className={css`${tw`relative flex flex-wrap items-center lg:px-12 px-6 py-4 pb-10 max-h-full w-full overflow-y-scroll`}; -webkit-overflow-scrolling: touch; `}>
    <div className={css`${tw`flex w-full justify-between items-center`}`}>
        <h3>Show me</h3>
        <h3> </h3>
        <p className={css`${tw`cursor-pointer`}`} onClick={()=> this.setState({showRentingChoices: false, showOverlay: false})}>Close</p>
    </div>
    <div className={css`${tw`w-full flex flex-wrap justify-between -mx-2`}`}>
        
        <RentingChoiceButton
            icon={OSCabIcon}
            title='Car for Outstation'
            description='The Luxury of having a Chauffeur'
            active={this.state.rentalType === 1}
            onClick={()=> { 
                    // eslint-disable-next-line
                    analytics.track('Chauffeur Clicked',{category:'clicks',label:'chauffeur choice'});
                    if(this.props.isChauffeurLoading || this.props.isSelfDriveLoading || this.props.isAirportLoading || this.props.isRentalLoading){
                        return;
                    }
                    if(this.props.ride.dest.loc === ''){
                        this.setState({ isOverlayActive: true, isSliderModalActive: true,isDestActive: true, isPickupActive:false, showOverlay: false,showRentingChoices: false,rentalType:1 });
                        this.props.dispatch(updateRentalType(1));
                    }
                    else{
                        this.setState({ 
                            isChauffeur: true,
                            rentalType: 1,
                            cars: this.state.isRating ? this.state.isChauffeurRating : this.state.isChauffeurPricing,
                            showRentingChoices: false,
                            showOverlay: false 
                        });
                        this.props.dispatch(updateRentalType(1));

                    }
                }
            }
        />
        <RentingChoiceButton
            icon={SDCabIcon}
            title='Self Drive Cars'
            description='The control of driving yourself'
            active={this.state.rentalType === 2}
            onClick={() => {
                // eslint-disable-next-line
                analytics.track('Selfdrive Clicked', { category: 'clicks', label: 'selfdrive choice' });
                if (this.props.isChauffeurLoading || this.props.isSelfDriveLoading || this.props.isAirportLoading || this.props.isRentalLoading) {
                    return;
                }
                this.setState({
                    isChauffeur: false,
                    rentalType: 2,
                    cars: this.state.isRating ? this.state.isSelfRating : this.state.isSelfPricing,
                    showRentingChoices: false,
                    showOverlay: false
                });
                this.props.dispatch(updateRentalType(2));
            }
            }
        />
        <RentingChoiceButton
            icon={ATCabIcon}
            title='Airport Transfers'
            description='Chauffer Driven Cabs from/to Airports'
            active={this.state.rentalType === 3}
            onClick={() => {
                // eslint-disable-next-line
                analytics.track('Airport Clicked', { category: 'clicks', label: 'airport choice' });
                if (this.props.isChauffeurLoading || this.props.isSelfDriveLoading || this.props.isAirportLoading) {
                    return;
                }
                if (this.props.ride.dest.loc === '') {
                    this.setState({ isOverlayActive: true, isSliderModalActive: true, isDestActive: true, isPickupActive:false, showOverlay: false, showRentingChoices: false, rentalType: 3 });
                    this.props.dispatch(updateRentalType(3));
                    this.props.dispatch(carsActions.fetchAirportVehicleDetails());
                }
                else {
                    this.setState({
                        isChauffeur: true,
                        rentalType: 3,
                        cars: this.state.isRating ? this.state.isAirportRating : this.state.isAirportPricing,
                        showRentingChoices: false,
                        showOverlay: false
                    });
                    this.props.dispatch(updateRentalType(3));
                }
            }
            }
        />
        <RentingChoiceButton
            icon={HRCabIcon}
            title='Hourly Rental'
            description='Hourly Rentals for intracity travel'
            active={this.state.rentalType >= 4}
            disabled={false}
            onClick={()=> { 
                    // eslint-disable-next-line
                    analytics.track('Rental Clicked',{category:'clicks',label:'airport choice'});
                    if(this.props.isChauffeurLoading || this.props.isSelfDriveLoading || this.props.isAirportLoading || this.props.isRentalLoading){
                        return;
                    }
                    if(this.props.ride.rentalType <= 3){
                        this.setState({ isOverlayActive: true, showHourlyPackageOptions: true, isDestActive: true, isPickupActive:false, showOverlay: false, showRentingChoices: false, rentalType:4 });
                        this.props.dispatch(updateRentalType(4));
                    }
                    else{
                        this.setState({ 
                            isRental: true,
                            rentalType: 4,
                            cars: this.state.isRating ? this.state.isRentalRating : this.state.isRentalPricing,
                            showRentingChoices: false,
                            showOverlay: false 
                        });
                        this.props.dispatch(updateRentalType(4));

                    }

                }
            }
        />

        
    </div>
    <div className={css`${tw`block`}`}>
        <h3>Filter them by</h3>
    </div>
    <div className={css`${tw`flex w-full flex-wrap justify-between mb-12`}`}>

        <div onClick={() => {                                       
                  // eslint-disable-next-line
                    analytics.track('Sort By Price',{category:'clicks',label:'price sorting'}); 
                    this.setState({ isRating: false, showRentingChoices: false, showOverlay: false }); 
                    this.handlePricingFilter();
                    }} className={styles.filterButtons}>
            <p className={css`${tw`uppercase font-bold`}`}>Price</p>
        </div>
        <div onClick={() => { 
             // eslint-disable-next-line
             analytics.track('Sort By Rating',{category:'clicks',label:'rating sorting'});
            this.setState({ isRating: true, showRentingChoices: false, showOverlay: false  });
            this.handleRatingFilter();                                
            }} className={styles.filterButtons}>
            <p className={css`${tw`uppercase font-bold`}`}>Rating</p>
        </div>
        <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
        <label className={css`${tw`block font-medium text-grey-dark text-lg`}`}>Vehicle: </label>
        <div>
        <PaymentRadio
            value='all'
            onClick={(e) => { this.setState({ vehicle: true, mini:false, sedan:false, suv:false }) }}
            active={this.state.vehicle === true}>
            <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                    All
                </span>
            </div>

        </PaymentRadio>
    </div>
        <div>
                <PaymentRadio
                    value='MINI'
                    onClick={(e) => { this.setState({ mini : !this.state.mini, vehicle:false }) }}
                    active={this.state.mini === true}>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`inline-block font-medium text-grey-dark text-lg`}`}>
                            Mini
                        </span>
                    </div>

                </PaymentRadio>
            </div>
            <div>
                <PaymentRadio
                    value='SEDAN'
                    onClick={(e) => { this.setState({ sedan : !this.state.sedan, vehicle:false  }) }}
                    active={this.state.sedan === true }>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            Sedan
                        </span>
                    </div>

                </PaymentRadio>
            </div>
            <div>
                <PaymentRadio
                    value='SUV'
                    onClick={(e) => { this.setState({ suv : !this.state.suv, vehicle:false  }) }}
                    active={this.state.suv === true }>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            SUV
                        </span>
                    </div>

                </PaymentRadio>
            </div>
<label className={css`${tw`p-2 block`}`}><input type="radio"/>All</label>
        </div>
        {this.state.rentalType ===2 && <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
        <label className={css`${tw`block font-medium text-grey-dark text-lg`}`}>Fuel Type: </label>
        <div>
        <PaymentRadio
            value='both'
            onClick={(e) => { this.setState({ fuelType: 'both' }) }}
            active={this.state.fuelType === 'both'}>
            <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                    Both
                </span>
            </div>

        </PaymentRadio>
    </div> 
        <div>
                <PaymentRadio
                    value='Petrol'
                    onClick={(e) => { this.setState({ fuelType: 'Petrol' }) }}
                    active={this.state.fuelType === 'Petrol'}>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            Petrol
                        </span>
                    </div>

                </PaymentRadio>
            </div>
            <div>
                <PaymentRadio
                    value='Diesel'
                    onClick={(e) => { this.setState({ fuelType: 'Diesel' }) }}
                    active={this.state.fuelType === 'Diesel'}>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            Diesel
                        </span>
                    </div>

                </PaymentRadio>
            </div>
        </div>}
        
        {this.state.rentalType ===2 && <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
        <label className={css`${tw`block font-medium text-grey-dark text-lg`}`}>Transmission: </label>
        <div>
        <PaymentRadio
                value='both'
                onClick={(e) => { this.setState({ transmission: 'both' }) }}
                active={this.state.transmission === 'both'}>
                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                    <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                        Both
                    </span>
                </div>

            </PaymentRadio>
        </div>
        <div>
                <PaymentRadio
                    value='Manual'
                    onClick={(e) => { this.setState({ transmission: 'Manual' }) }}
                    active={this.state.transmission === 'Manual'}>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            Manual
                        </span>
                    </div>

                </PaymentRadio>
            </div>
            <div>
                <PaymentRadio
                    value='Automatic'
                    onClick={(e) => { this.setState({ transmission: 'Automatic' }) }}
                    active={this.state.transmission === 'Automatic'}>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            Automatic
                        </span>
                    </div>

                </PaymentRadio>
            </div>
        </div>}
        {this.state.rentalType ===2 && <div className={css`${tw`block justify-around items-center content-around p-2`}`}>
        <label className={css`${tw`block font-medium text-grey-dark text-lg`}`}>Kilometers: </label>
        <div>
        <PaymentRadio
            value='both'
            onClick={(e) => { this.setState({ kilometers: 'both' }) }}
            active={this.state.kilometers === 'both'}>
            <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                    Both
                </span>
            </div>

        </PaymentRadio>
    </div>
        <div>
                <PaymentRadio
                    value='unlimited'
                    onClick={(e) => { this.setState({ kilometers: 'unlimited' }) }}
                    active={this.state.kilometers === 'unlimited'}>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            Unlimited
                        </span>
                    </div>

                </PaymentRadio>
            </div>
            <div>
                <PaymentRadio
                    value='limited'
                    onClick={(e) => { this.setState({ kilometers: 'limited' }) }}
                    active={this.state.kilometers === 'limited'}>
                    <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                        <span className={css`${tw`block font-medium text-grey-dark text-lg`}`}>
                            Limited
                        </span>
                    </div>

                </PaymentRadio>
            </div>
        </div>}
       
    </div>
</div>
</SliderModal> */}
