import React from 'react';
import {css} from 'emotion';
import {Spring, Transition, interpolate, animated, config} from 'react-spring/renderprops';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import _ from 'lodash';
import '../../assets/fonts/fonts.css';
import GradientScroll from 'react-gradient-scroll-indicator';
import StarRatings from 'react-star-ratings';
import Switch from "react-switch";
import { navigate } from 'gatsby-link';

/* -------------------------------------------------------
    * Popup Component starts here
----------------------------------------------------------*/ 
/* global tw */
const styles = {
    header: css`
        ${tw`w-full flex justify-between items-center`};
        // height: 5.9vh;
        height: 9.5488257108vh;
        @media (min-width: 992px){
            height: 9.5488257108vh;
        } 
    `,
    listWrapper: css`
        ${tw`w-full bg-white overflow-y-scroll overflow-x-hidden scrolling-touch`};
        border-radius: 16px 16px 0 0;
        height: 91.4511742891vh;
        // transform: translateY(-1vh);
        padding-bottom: 100px;
        box-shadow: 0 0 25px -2px rgba(0,0,0,0.3);
        -webkit-overflow-scrolling: touch;

    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-between items-center px-2 lg:px-6 lg:px-0 -mx-2 mx-auto scrolling-touch`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
    listingRow: css`
        ${tw`flex flex-wrap w-full items-center h-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            // max-width: 75rem;
        }
    `,
    itineraryBlob: css`
        ${tw`w-full lg:w-1/2 py-0 px-2 lg:py-4 `}
        &:hover{
            cursor: pointer;
            background: rgba(240,242,245, 0.4);
            border-radius: 8.09px;
            color: purple;
        }

    `,
    fromToLocations: css`
        ${tw`lg:text-2xl text-normal font-bold opacity-70`}
        color: #222222;
        letter-spacing: -0.73px;
    `,
    blackOverlay: css`
        ${tw`fixed z-200 h-screen w-screen overflow-hidden pin-t`};
        background: rgba(0,0,0,0.6);
    `,
    choiceFilterButton: css`
        ${tw`flex items-center px-4 py-2 fixed z-50 pin-b pin-x bg-white mx-auto mb-2 md:mb-4 lg:mb-8 justify-between`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        transition: all 0.25s ease-in-out;
        width: 95vw;
        height: 3.5rem;
        @media (min-width: 992px){
            width: 33vw;
            height: 5rem; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            // background: -webkit-linear-gradient(#AA0BCA, #7D0EDE);
            // -webkit-background-clip: text;
            -webkit-text-fill-color: purple;
            color: purple;
            ${tw`font-bold uppercase`};
            font-size: 0.8rem;
            @media(min-width: 992px){
                font-size: 1rem;
            }
            span{
                ${ tw`block text-sm font-medium normalcase opacity-60`};
                color: black;
                -webkit-text-fill-color: black;
                font-size: 0.6rem;
                 @media(min-width: 992px){
                    font-size: 0.7rem;
                }
            }
        }
    `,
    filterButton: css`
        ${tw`flex items-center px-4 py-2 fixed z-50 pin-b pin-x bg-white mx-auto mb-2 md:mb-4 lg:mb-8 justify-center align-center`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        transition: all 0.25s ease-in-out;
        width: 15vw;
        height: 15vw;
        @media (min-width: 992px){
            width: 5vw;
            height: 5vw; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            // background: -webkit-linear-gradient(#AA0BCA, #7D0EDE);
            // -webkit-background-clip: text;
            -webkit-text-fill-color: purple;
            color: purple;
            ${tw`font-bold uppercase`};
            font-size: 0.8rem;
            @media(min-width: 992px){
                font-size: 1rem;
            }
            span{
                ${ tw`block text-sm font-medium normalcase opacity-60`};
                color: black;
                -webkit-text-fill-color: black;
                font-size: 0.6rem;
                 @media(min-width: 992px){
                    font-size: 0.7rem;
                }
            }
        }
    `,
    filterButtons: css`
        ${tw`flex text-center lg:text-left lg:px-8 mx-2 px-6 py-4 -mx-2 rounded w-1/2 bg-grey-light items-center cursor-pointer`};
    `,
    bookingForm: css`
        ${tw`items-center flex flex-wrap bg-white mt-12 relative w-full overflow-hidden mx-4`};
        border-radius: 8.09px;
        box-shadow: 0 6px 24px rgba(0,0,0,0.2);
        height: max-content;
        // max-height: initial;
        @media (min-width: 992px){
            height: 38.195302843vh;
        }

    `,
    datePicker: css`
        ${tw`list-reset`};

        li{
            ${tw`my-4 py-1 lg:py-2 px-1 lg:px-4 cursor-pointer`};
            &:hover{
                ${tw`bg-grey-light`}
            }
        }
        li.active{
            ${tw`bg-grey-dark text-white`};
        }

    `,
    autocompleteSuggestions: css`
        ${tw`list-reset w-full`};
        li{
            ${tw`py-2 px-1 my-1 text-grey-darker font-sans lg:text-lg hover:bg-grey-light`};

        }
    `,
    dateTimeScrollWrapper: css`
        ${tw`w-gr1/3 lg:text-2xl mr-6 lg:px-2 py-2 overflow-y-scroll`}; 
        height:30vh;
        -webkit-overflow-scrolling: touch;
        -webkit-mask-image: -webkit-gradient(linear, left 90%,left bottom, 0%(rgba(0,0,0,1)), 25%(rgba(0,0,0,0)),75%(rgba(0,0,0,0)), 100%(rgba(0,0,0,1)))
    `,
    itineraryLine: css`
        ${tw`w-full flex flex-wrap justify-between py-1 lg:py-1 px-6`}
        // border-bottom: 1px solid #F0F2F5;
    `,
}
const CarPopup = (props) => {
    let car = props.car;
    let couponApplied, originalPrice, finalPrice, type;
    if(typeof props.car === `undefined`){
        return null;
    }
    if (props.car.hasOwnProperty('vendors') && props.car.category === 'SELF DRIVE') {
        type='self';
        if (props.selectedVendor.couponValue <= 0){
            couponApplied = false;
        }
        else{
            couponApplied = true;
            }    
            originalPrice = Math.round(props.selectedVendor.base_fare).toLocaleString();
            finalPrice = Math.round(props.selectedVendor.discountedValue).toLocaleString();
            let selfVendors = Object.assign([],props.car.vendors);
            // console.log(selfVendors);
            Object.assign(car,{'vendors':selfVendors});
            // console.log(car);
    }
    else {
        type='';
        if (props.selectedVendor.couponValue <= 0 ){
            couponApplied = false;
        }
        else{
            couponApplied = true;
        }
            originalPrice = Math.round(props.selectedVendor.ride_amt).toLocaleString();
            finalPrice = Math.round(props.selectedVendor.discountedValue).toLocaleString();
    }
    let disableFuelFilter = true;
    car.vendors.map( vendor => {
        if(vendor.measure_cd.includes('with fuel')){
            disableFuelFilter = false
        }
    })
    return(
        <div className={popupStyles.container}>
        <Spring native config={config.slow} from={{transform:`translateY(${10}rem)` }} to={{transform:`translateY(${0}rem)`}}>
            {s =>
             <animated.div className={popupStyles.firstBox} style={s}>
                <div className={css`${tw`flex flex-row justify-around items-center w-full`}`}>
                    <img className={popupStyles.carImage} src={car.sub_category_url} />
                    <div className={css`${tw`pl-4`}`}>
                    <p className={cardStyles.carSpecs} style={{marginLeft:'75%',textTransform:'none',cursor:'pointer'}} onClick={props.close}>Close</p>
                    <h3 className={cardStyles.carName}>{car.sub_category}</h3>
                    <div className={css`${tw`flex flex-row`}`}>                        
                        <img src={require('../SvgComponents/seats.svg')}/>
                        <p className={cardStyles.carSpecs}>{car.no_of_seats}</p>
                        {car.category === "SELF DRIVE" && <img src={require('../SvgComponents/gear-type.svg')}/>}
                        {car.category === "SELF DRIVE" && <p className={cardStyles.carSpecs}>{car.vendors[0].gear_type}</p>}
                        {car.category === "SELF DRIVE" && <p className={cardStyles.carSpecs}>{car.vendors[0].fuel_type}</p>}
                        <p className={cardStyles.carSpecs}>A/C</p>
                    </div>
                    <Spring native config={config.slow} from={{transform:`translateX(${-15}rem)`,opacity:0 }} to={{transform:`translateX(${0}rem)`,opacity:1}}>
                        {values =>
                        <animated.div className={popupStyles.lastBox} style={{...values,position:'absolute',right:10}}>
                            <p>Proceed</p>
                            <AniLink to="/reviewPage/" hex="#AA0BCA" duration={2} className={css`${tw`no-underline absolute pin-t pin-l z-10 h-full w-full`}`}></AniLink> 
                        </animated.div>
                        }
                    </Spring>
                    </div>
                </div>
                    <div className={css`${tw`flex flex-row -ml-5 mt-12`}`}>
                        <div className={css`${tw`ml-16 w-30 z-200 align-center bg-white justify-center border-solid border-2 border-borderColor rounded-full mx-12 px-2 py-2 items-center self-center`}`}>
                        <p className={css`${tw`font-light opacity-50 text-xs tracking-tight`}`}>                            
                        Service Provided By
                        </p>
                        </div>
                        <div className={css`${tw`w-full z-100 h-px bg-grey absolute self-center justify-start align-center`}`}/>
                    </div>

                    <div className={css`${tw`w-full flex flex-row items-center justify-between`}`}>
                    <div className={css`${tw`flex flex-row items-center justify-between text-left mt-1 mx-8 pl-5`}`}>
                                    <div className={css`${tw`h-8 mt-2`}`}
                                        style={{
                                            width: '4rem', 
                                            backgroundImage: `url(${props.selectedVendor.image_url})`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                        }}>
                                       
                                    </div>
                                    
                                    <div className={css`${tw`h-12 w-px bg-grey mx-2`}`}/>
                                    
                                    <p onClick={props.onReviewClick} className={css`${tw`flex flex-row align-center bg-white justify-center px-2 cursor-pointer items-center w-1/3 text-sm text-black font-medium`}; left: 10%; margin-top: -1.5rem `}>
                                    <span className={css`${tw`inline-flex`}`}><img src={require('../SvgComponents/starLabelSvg.svg')} alt="rating" /></span>
                                    {props.selectedVendor.rating} 
                                    </p>
                                    <p onClick={props.onReviewClick} className={css`${tw`flex flex-row align-center bg-white justify-center px-2 cursor-pointer mt-6 items-center w-1/3 text-sm text-black font-normal tracking-tight`}; margin-left: -4rem `}>
                                        Reviews
                                        <Spring from={{ r: 0 }} 
                                        to={{ r: props.isReviewActive ? -180 : 0}}
                                        native>
                                    {({r})=>
                                        <animated.div style={{transform: r.interpolate(r => `rotateX(${r}deg)`)}}>
                                            <img className={css`${tw`h-2 ml-2`}`} src={require('../SvgComponents/angle-down.svg')}/>
                                        </animated.div>
                                    }
                                </Spring>
                                    </p>
                                    
                                </div>

                    <div className={css`${tw`w-auto h-auto px-4 border-solid border-2 border-borderColor rounded-lg mx-2`}`}>
                        {couponApplied && 
                        <div className={css`${tw`flex flex-row items-center justify-evenly pt-2`}`}>

                                <p className={css`${tw`font-light line-through opacity-50 text-sm tracking-tight`}`}>
                                    &#8377; {originalPrice} 
                                </p>
                                <div className={css`${tw`flex items-center ml-2`}`}>
                                    <img className={css`${tw`inline-flex`}`} src={require('../SvgComponents/couponLabelSvg.svg')} alt="Zyppys" />
                                    { /* <p className={cardStyles.couponText}>Best Coupon Applied</p> */}
                                </div>
                        </div>
                        }
                        <p className={css`${tw`font-bold text-xl tracking-tight`}`}>
                            &#8377;{finalPrice}
                        </p>
                        {props.selectedVendor.hasOwnProperty('package_kms') &&  <p className={css`${tw`font-light opacity-50 text-sm tracking-tight uppercase`}`}>
                            For {type !== 'self' ?
                             props.selectedVendor.package_kms.toLocaleString()+' Kms' 
                             : props.selectedVendor.hasOwnProperty('package_kms') ? 
                             ((typeof props.selectedVendor.package_kms === 'string'? 
                             (props.selectedVendor.package_kms.includes('kms') || props.selectedVendor.package_kms.includes('Kms') ?  props.selectedVendor.package_kms : props.selectedVendor.package_kms + ' Kms')
                             : props.selectedVendor.package_kms.toLocaleString() (typeof props.selectedVendor.package_kms === 'string' && (props.selectedVendor.package_kms.includes('kms')||props.selectedVendor.package_kms.includes('Kms')) ? '': 'Kms'))) 
                             : props.myChoizeKms      
                            }
                        </p> }
                    </div>   

                    </div>
                    <div onClick={props.onTermsClick} className={css`${tw`mt-1 ml-2 lg:ml-4 w-30 z-200 flex flex-row align-center bg-white justify-center border-solid border-2 border-borderColor rounded-full px-2 cursor-pointer items-center`}`}>
                        <p className={css`${tw`font-light opacity-50 text-xs tracking-tight`}`}>                            
                        Terms and Conditions
                        </p>
                        <Spring from={{ r: 0 }} 
                            to={{ r: props.isTermsActive ? -180 : 0}}
                            native>
                        {({r})=>
                            <animated.div style={{transform: r.interpolate(r => `rotateX(${r}deg)`)}}>
                                <img className={css`${tw`h-2 ml-2`}`} src={require('../SvgComponents/angle-down.svg')}/>
                            </animated.div>
                        }
                    </Spring>
                    </div>
                    <div onClick={props.onPriceClick} className={css`${tw`mt-1 ml-2 lg:ml-4 w-30 z-200 flex flex-row align-center bg-white justify-center border-solid border-2 border-borderColor rounded-full px-2 cursor-pointer items-center`}`}>
                        <p className={css`${tw`font-light opacity-50 text-xs tracking-tight`}`}>                            
                        Price Breakup
                        </p>
                        <Spring from={{ r: 0 }} 
                            to={{ r: props.isBreakupActive ? -180 : 0}}
                            native>
                        {({r})=>
                            <animated.div style={{transform: r.interpolate(r => `rotateX(${r}deg)`)}}>
                                <img className={css`${tw`h-2 ml-2`}`} src={require('../SvgComponents/angle-down.svg')}/>
                            </animated.div>
                        }
                    </Spring>
                    </div>
                        {type !== '' && !disableFuelFilter && <div className={css`${tw`mt-3 z-200 flex flex-row align-center bg-white justify-center px-1 lg:px-2 cursor-pointer items-center`}`}>
                        <label htmlFor="material-switch">
                        {!props.isFuel && <img className={css`${tw`h-8 mr-1 lg:mr-2 cursor-pointer`}`} src={require('../../assets/img/fuel.png')}/>}
                        {props.isFuel && <img className={css`${tw`h-8 mr-1 lg:mr-2 cursor-pointer`}`} src={require('../../assets/img/fuelSelected.svg')}/>}
                            <Switch
                                checked={props.isFuel}
                                onChange={props.onFuelClick}
                                onColor="#D2B1EF"
                                onHandleColor="#740DCE"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                                activeBoxShadow="0 0 5px 2px rgba(0,0,0,.35)"
                                height={20}
                                width={40}
                                className="react-switch"
                                id="material-switch"
                                disabled={disableFuelFilter}
                            />
                            </label>
                        </div>}
                    {props.isReviewActive &&

                            <Spring
                                native
                                from={{ height: 0, opacity: 0 }}
                                to={{ display: props.isReviewActive ? 'flex' : 'none', height: 'auto', opacity: props.isReviewActive ? 1 : 0, zIndex: props.isReviewActive ? 10 : 0 }}
                                config={config.stiff}
                                clamp>
                                {values =>
                                    <animated.div style={values} className={css`${tw`flex-wrap w-full`}`}>
                                        <p className={css`${tw`block w-full mt-6 px-6 leading-loose font-medium text-xl text-grey-dark`}`}>Reviews</p>
                                        <div className={css`${tw` m-6 mt-2 w-full`}`}>
                                            {/*props.selectedVendorReview.hasOwnProperty("eachRatingsCount") && props.selectedVendorReview.eachRatingsCount.length!==0 ?
                                        <div className={css`${tw` m-6 mt-2 w-full`}`}>
                                       {props.selectedVendorReview.hasOwnProperty("eachRatingsCount") &&
                                                <div className={css`${tw`w-3/4`}`}>
                                                {props.selectedVendorReview.eachRatingsCount.reverse().map((ratings,i)=>{
                                                    if(ratings.rating!==0){
                                                    return(
                                                    <label className={cardStyles.reviewText}><StarRatings
                                                    rating={ratings.rating}
                                                    starDimension="20px"
                                                    starSpacing="1px"
                                                    starRatedColor="rgb(116,13,206)"
                                                    /> {ratings.count}</label>
                                                )}})}
                                                    </div>
                                                    */}
                                            {props.selectedVendorReview && props.selectedVendorReview.hasOwnProperty("topReviews") && props.selectedVendorReview.topReviews.length !== 0 ?
                                                <div className={css`${tw`mt-2 leading-loose text-grey-dark`}`}>
                                                    {/*<p className={css`${tw`leading-loose font-medium text-lg text-grey-dark`}`}>Top Reviews</p>*/}
                                                    {props.selectedVendorReview.topReviews.map((review) => {
                                                            let initial = review.reviewerName!==null ? (review.reviewerName.slice(0, 1)).toUpperCase() : null;
                                                        return (<div className={css`${tw`mt-4`}`}>
                                                            <div className={css`${tw`inline-block h-7 w-7 rounded-full text-center text-white`}; background-color: ${"#" +
                                                            Math.random()
                                                              .toString(16)
                                                              .slice(2, 8)}`}>{initial}</div>
                                                            <h5 className={css`${tw`inline-block ml-3 align-text-top`}; margin-top: -0.75rem`}>{review.reviewerName}</h5>
                                                            <p className={css`${tw`block ml-10 -mt-6 leading-tight`}; margin-top: -1.5rem`}>
                                                            <StarRatings
                                                                rating={review.rating}
                                                                starDimension="15px"
                                                                starSpacing="1px"
                                                                starRatedColor="rgb(116,13,206)"
                                                            /></p>
                                                            <p className={css`${tw`block ml-10 leading-tight`}`}>{review.comments}</p>
                                                            
                                                            </div>)}
                                                    )
                                                    }
                                                </div> : <div className={css`${tw`mt-2 leading-loose text-grey-dark`}`}>
                                                    <p>No Reviews</p>
                                                </div>
                                            }
                                            { /*</div>
                                                :
                                                <div className={css`${tw` m-6 mt-2 w-full`}`}>
                                                <div className={css`${tw`mt-2 leading-loose text-grey-dark`}`}>
                                                <p>No Ratings and Reviews</p>
                                                </div>
                                               </div>*/}
                                            {/*<p className={css`${tw`block w-full px-6 leading-loose text-grey-dark`}`}>{review}</p>*/}
                                        </div>
                                    </animated.div>
                                }
                            </Spring>
                        }
                    {props.isTermsActive &&

                    <Spring 
                        native
                        from={{height: 0, opacity: 0}} 
                        to={{ display: props.isTermsActive ? 'flex' : 'none', height: 'auto', opacity: props.isTermsActive? 1:0, zIndex: props.isTermsActive? 10:0}}
                        config={config.stiff}
                        clamp>
                    {values =>
                        <animated.div style={values} className={css`${tw`flex-wrap w-full`}`}>

                            {
                                props.selectedVendor.hasOwnProperty('terms_conditions') ?
                                props.selectedVendor.terms_conditions.map((terms) => {
                                        return (
                                            <p className={css`${tw`block w-full px-6 leading-loose text-grey-dark`}`}>{terms}</p>
                                        );
                                    }) :
                                    <div className={css`${tw`m-6`}`}>
                                        <a target="_blank" href={props.selectedVendor.terms_conditions_url}>
                                            {props.selectedVendor.terms_conditions_url}
                                        </a>
                                    </div>
                            }
                            </animated.div>
                    }
                </Spring>
                    }
                {props.isBreakupActive &&
                <Spring 
                        native
                        from={{height: 0, opacity: 0}} 
                        to={{ display: props.isBreakupActive ? 'flex' : 'none', height: 'auto', opacity: props.isBreakupActive? 1:0, zIndex: props.isBreakupActive? 10:0}}
                        config={config.stiff}
                        clamp>
                    {values =>
                        <animated.div style={values} className={css`${tw`flex-wrap w-full overflow-scroll`}`}>
                {type !== 'self' ?

                                    <div className={styles.itineraryLine}>
                                        {props.selectedVendor.hasOwnProperty('package_kms') && <div className={styles.itineraryLine}>
                                            {
                                                props.selectedVendor.actual_cost_per_km > 0 ?
                                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Base Fare
                                        {props.selectedVendor.base_fare === props.selectedVendor.package_kms * props.selectedVendor.actual_cost_per_km
                                                            ?
                                                            <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}> {props.selectedVendor.package_kms.toLocaleString()} Kms x &#8377; {props.selectedVendor.actual_cost_per_km.toLocaleString()}</span>
                                                            :
                                                            <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}> {props.selectedVendor.package_kms.toLocaleString()} Kms</span>
                                                        }
                                                    </p>
                                                    :
                                                    <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Package Fare
                                            <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}> {props.selectedVendor.package_kms.toLocaleString()} Kms </span>
                                                    </p>
                                            }
                                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {Math.round(props.selectedVendor.base_fare).toLocaleString()}</p>
                                        </div>}

                                        {props.selectedVendor.hasOwnProperty("driver_charges_per_day") && props.selectedVendor.driver_charges_per_day !== null && props.selectedVendor.driver_charges_per_day > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Driver Charges
                                        <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}>&#8377; {props.selectedVendor.driver_charges_per_day.toLocaleString()} x {props.selectedVendor.no_of_days} Days </span>
                                                </p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {Math.floor(props.selectedVendor.driver_charges_per_day * props.selectedVendor.no_of_days).toLocaleString()}</p>
                                            </div>}

                                        {/* {props.selectedVendor.hasOwnProperty("night_charges") && props.selectedVendor.night_charges !== null && props.selectedVendor.night_charges > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Night Charges (Extra)</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.night_charges.toLocaleString()} </p>
                                            </div>
                                        } */}

                                        {props.selectedVendor.couponValue > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Coupon Discount </p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>-&#8377; {props.selectedVendor.couponValue.toLocaleString()}</p>
                                            </div>
                                        }

                                        {props.selectedVendor.hasOwnProperty("service_tax_amt") && props.selectedVendor.service_tax_amt !== null && parseInt(props.selectedVendor.service_tax_amt) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>GST</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.service_tax_amt.toLocaleString()}</p>
                                            </div>
                                        }

                                        {props.selectedVendor.hasOwnProperty("state_tax") && props.selectedVendor.state_tax > 0 && parseInt(props.selectedVendor.state_tax) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Border Tax</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.state_tax.toLocaleString()}</p>
                                            </div>
                                        }

                                        {props.selectedVendor.hasOwnProperty("service_charges") && props.selectedVendor.service_charges !== null && parseInt(props.selectedVendor.service_charges) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Service Charges</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.service_charges.toLocaleString()}</p>
                                            </div>
                                        }

                                        {props.selectedVendor.hasOwnProperty("toll_tax") && props.selectedVendor.toll_tax !== null && parseInt(props.selectedVendor.toll_tax) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Tolls</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.toll_tax.toLocaleString()}</p>
                                            </div>
                                        }

                                        {props.selectedVendor.hasOwnProperty("extra_cost_per_km") && props.selectedVendor.extra_cost_per_km !== null && parseInt(props.selectedVendor.extra_cost_per_km) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Km</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.extra_cost_per_km.toLocaleString()}</p>
                                            </div>
                                        }

                                        {props.selectedVendor.hasOwnProperty("extra_cost_per_hr") && props.selectedVendor.extra_cost_per_hr !== null && parseInt(props.selectedVendor.extra_cost_per_hr) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Hour</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.extra_cost_per_hr.toLocaleString()}</p>
                                            </div>
                                        }
                                    </div>

                                    :

                                    <div className={styles.itineraryLine}>
                                        <div className={styles.itineraryLine}>
                                           {props.selectedVendor.hasOwnProperty('package_kms') && <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Base Fare
                                <span className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker block opacity-60 py-2`}`}>{typeof props.selectedVendor.package_kms === 'string' ? props.selectedVendor.package_kms : props.selectedVendor.package_kms.toLocaleString()} {typeof props.selectedVendor.package_kms === 'string' && (props.selectedVendor.package_kms.includes('kms') || props.selectedVendor.package_kms.includes('Kms')) ? '' : 'Kms'}</span>
                                            </p>}
                                            <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {Math.round(props.selectedVendor.base_fare).toLocaleString()}</p>
                                        </div>

                                        {props.selectedVendor.couponValue > 0 && props.selectedVendor.coupon !== null &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Coupon Discount </p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>-&#8377; {props.selectedVendor.couponValue.toLocaleString()}</p>
                                            </div>
                                        }
                                        <div className={styles.itineraryLine}>
                                        </div>
                                        {/* {props.selectedVendor.hasOwnProperty("security_deposit") && props.selectedVendor.security_deposit !== null && parseInt(props.selectedVendor.security_deposit) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Security Deposit (Extra)</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.security_deposit.toLocaleString()}</p>
                                            </div>
                                        } */}

                                        {props.selectedVendor.hasOwnProperty("service_tax_amt") && props.selectedVendor.service_tax_amt !== null && parseInt(props.selectedVendor.service_tax_amt) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>GST</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.service_tax_amt.toLocaleString()}</p>
                                            </div>
                                        }

                                        {/* {props.selectedVendor.hasOwnProperty("delivery_charges") && props.selectedVendor.delivery_charges !== null && parseInt(props.selectedVendor.delivery_charges) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Delivery Charges (Extra)</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.delivery_charges.toLocaleString()}</p>
                                            </div>
                                        } */}

                                        {props.selectedVendor.hasOwnProperty("extra_cost_per_km") && props.selectedVendor.extra_cost_per_km !== null && parseInt(props.selectedVendor.extra_cost_per_km) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Km</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.extra_cost_per_km.toLocaleString()}</p>
                                            </div>
                                        }

                                        {props.selectedVendor.hasOwnProperty("extra_cost_per_hr") && props.selectedVendor.extra_cost_per_hr !== null && parseInt(props.selectedVendor.extra_cost_per_hr) > 0 &&
                                            <div className={styles.itineraryLine}>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-medium text-grey-darker`}`}>Extra Cost per Hr</p>
                                                <p className={css`${tw`font-sans w-full md:w-auto font-bold text-grey-darker`}`}>&#8377; {props.selectedVendor.extra_cost_per_hr.toLocaleString()}</p>
                                            </div>
                                        }


                                    </div>
                                }
                                </animated.div>
                    }
                </Spring>
                }
            </animated.div>
            }
        </Spring>
        <Spring native config={config.slow} from={{transform:`translateY(${-10}rem)`,opacity:0 }} to={{transform:`translateY(${0}rem)`,opacity:1}}>
            {values =>
                /* props.car.vendors.length > 1 ? 1 : -2 */
            <animated.div className={popupStyles.secondBox} style={{...values,visibility:car.vendors.length === 1 ? 'hidden' : 'visible'}}>
            <GradientScroll primaryColor="transparent">
            { props.distinctChauffeur && props.distinctSelf && props.rentalType === 1 && props.distinctSelf.filter( selfVendors => selfVendors.ref_category_id === props.car.category_id ).map((selfVendors,i) => {
                let topKms=0;
                let topPrice=0;
                const highestKms=() => {
                    selfVendors.vendors.map((vendor) => {
                        let kms = parseInt(vendor.hasOwnProperty('package_kms') && vendor.package_kms.split(' ')[0]);
                        if (kms > topKms && kms !== 'unlimited') {
                            topKms = kms;
                        }
                        // console.log(kms);
                    })
                    // console.log(topKms);
                return topKms;
            }
            const highestPrice=() => {
                selfVendors.vendors.map((vendor) => {
                    let price = vendor.discountedValue;
                    if (price > topPrice && price !== 'unlimited') {
                        topPrice = price;
                    }
                    // console.log(price);
                })
                // console.log(topPrice);
            return topPrice;
        }
                return(
                        <div>
                        <div 
                        // onClick={()=>{props.onClick(vendor)}} 
                        onClick={() => { props.onSelfClick()}}
                        className={css`${tw`w-full flex flex-row items-center justify-between cursor-pointer`}`}>
                                <div className={css`${tw`flex flex-row items-center justify-between text-left mx-8 py-6 px-5`}`}>
                                                <div className={css`${tw`h-8 mt-2 text-sm text-black font-medium`}`}>
                                                Self Drive Cars Available From
                                                </div>
                                            </div>
            
                                <div className={css`${tw`w-auto h-auto px-4 mx-2`}`}>
                                    
                                    <div className={css`${tw`flex flex-row items-center justify-evenly pt-2 `}`}>
                                        <p className={css`${tw`font-bold text-xl tracking-tight`}`}>
                                            &#8377; {Math.round(highestPrice()).toLocaleString()}
                                        </p>
                                    </div>
                                    <p className={css`${tw`font-light opacity-50 text-sm tracking-tight uppercase`}`}>
                                        For {highestKms() !== 0 ? highestKms() : 'Unlimited'} Kms
                                    </p> 
                                </div>                
                                </div>
                                <div className={css`${tw`-ml-5 w-full z-100 h-px bg-grey absolute self-center justify-end align-center`}`}/>
                                </div>
                    )
            })
        }
        { props.distinctChauffeur && props.distinctSelf && props.rentalType === 2 && props.distinctChauffeur.filter( chauffeurVendors => chauffeurVendors.category_id === props.car.ref_category_id ).map((chauffeurVendors,i) => {
            return(
                <div>
                <div 
                // onClick={()=>{props.onClick(vendor)}} 
                onClick={() => { props.onChauffeurClick()}}
                className={css`${tw`w-full flex flex-row items-center justify-between cursor-pointer`}`}>
                        <div className={css`${tw`flex flex-row items-center justify-between text-left mx-8 py-6 px-5`}`}>
                                        <div className={css`${tw`h-8 mt-2 text-sm text-black font-medium`}`}>
                                        Chauffeur Driven Cars Available From
                                        </div>
                                    </div>
    
                        <div className={css`${tw`w-auto h-auto px-4 mx-2`}`}>
                            
                            <div className={css`${tw`flex flex-row items-center justify-evenly pt-2 `}`}>
                                <p className={css`${tw`font-bold text-xl tracking-tight`}`}>
                                    &#8377; {Math.round(chauffeurVendors.vendors[0].discountedValue).toLocaleString()}
                                </p>
                            </div>
                            <p className={css`${tw`font-light opacity-50 text-sm tracking-tight uppercase`}`}>
                                For {chauffeurVendors.vendors[0].package_kms.toLocaleString()} Kms     
                            </p> 
                        </div>                
                        </div>
                        <div className={css`${tw`-ml-5 w-full z-100 h-px bg-grey absolute self-center justify-end align-center`}`}/>
                        </div>
            )
    })
}
                {type === '' && 
                    props.car.vendors.map((vendor)=>{

                        if(props.selectedVendor !== null && props.selectedVendor !== undefined && vendor.account_id !== props.selectedVendor.account_id)
                        return(
                            <div>
                    <div onClick={()=>{props.onClick(vendor)}} className={css`${tw`w-full flex flex-row items-center justify-between cursor-pointer`}`}>
                            <div className={css`${tw`flex flex-row items-center justify-between text-left mt-1 mx-8 py-6 px-5`}`}>
                                            <div className={css`${tw`h-8 mt-2`}`}
                                                style={{
                                                    width: '4rem', 
                                                    backgroundImage: `url(${vendor.image_url})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                }}>

                                            </div>
                                            
                                            <div className={css`${tw`h-8 w-px bg-grey mx-2`}`}/>
                                            
                                            <p className={css`${tw`w-1/3 text-sm text-black font-medium`}; left: 10%`}>
                                                <span className={css`${tw`inline-flex`}`}><img src={require('../SvgComponents/starLabelSvg.svg')} alt="rating" /></span>
                                                {vendor.rating}
                                            </p>
                                        </div>

                            <div className={css`${tw`w-auto h-auto px-4 mx-2`}`}>
                                
                                <div className={css`${tw`flex flex-row items-center justify-evenly pt-2 `}`}>
                                    <p className={css`${tw`font-bold text-xl tracking-tight`}`}>
                                        &#8377; {Math.round(vendor.discountedValue).toLocaleString()}
                                    </p>
                                    {vendor.couponValue > 0 && vendor.couponValue !== null &&
                                    <div className={css`${tw`flex items-center ml-2`}`}>
                                        <img className={css`${tw`inline-flex`}`} src={require('../SvgComponents/couponLabelSvg.svg')} alt="Zyppys" />
                                        { /* <p className={cardStyles.couponText}>Best Coupon Applied</p> */}
                                    </div>
                                    }
                                </div>
                                <p className={css`${tw`font-light opacity-50 text-sm tracking-tight uppercase`}`}>
                                    For {vendor.package_kms.toLocaleString()} Kms      
                                </p> 
                            </div>                
                            </div>
                            <div className={css`${tw`-ml-5 w-full z-100 h-px bg-grey absolute self-center justify-end align-center`}`}/>
                            </div>
                        )

                })}
                {type !== '' &&
                    car.vendors.map((vendor)=>{
                        if(props.selectedVendor !== null && props.selectedVendor !== undefined && vendor.account_id !== props.selectedVendor.account_id || vendor.discountedValue !== props.selectedVendor.discountedValue || (vendor.hasOwnProperty('package_kms') && (vendor.package_kms !== props.selectedVendor.package_kms) )){
                        if(!props.isFuel && vendor.measure_cd.includes('without fuel'))
                        {
                        return(
                        <div>
                        <div onClick={()=>{props.onClick(vendor)}} className={css`${tw`w-full flex flex-row items-center justify-between cursor-pointer`}`}>
                        <div className={css`${tw`flex flex-row items-center justify-between text-left mt-1 mx-8 py-6 px-5`}`}>
                                        <div className={css`${tw`h-8 mt-2`}`}
                                            style={{
                                                width: '4rem', 
                                                backgroundImage: `url(${vendor.image_url})`,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                            }}>

                                        </div>
                                        
                                        <div className={css`${tw`h-8 w-px bg-grey mx-2`}`}/>
                                        
                                        <p className={css`${tw`w-1/3 text-sm text-black font-medium`}; left: 10%`}>
                                            <span className={css`${tw`inline-flex`}`}><img src={require('../SvgComponents/starLabelSvg.svg')} alt="rating" /></span>
                                            {vendor.rating}
                                        </p>
                                    </div>

                        <div className={css`${tw`w-auto h-auto px-4 mx-2`}`}>
                            
                            <div className={css`${tw`flex flex-row items-center justify-evenly pt-2 `}`}>
                                <p className={css`${tw`font-bold text-xl tracking-tight`}`}>
                                    &#8377; {Math.round(vendor.discountedValue).toLocaleString()}
                                </p>
                                {vendor.couponValue > 0 && vendor.couponValue !== null &&
                                <div className={css`${tw`flex items-center ml-2`}`}>
                                    <img className={css`${tw`inline-flex`}`} src={require('../SvgComponents/couponLabelSvg.svg')} alt="Zyppys" />
                                    { /* <p className={cardStyles.couponText}>Best Coupon Applied</p> */}
                                </div>
                                }
                            </div>
                            <p className={css`${tw`font-light opacity-50 text-sm tracking-tight uppercase`}`}>
                                For {vendor.hasOwnProperty('package_kms') ? (vendor.package_kms.includes('kms') || vendor.package_kms.includes('Kms') ?  vendor.package_kms.toLocaleString() : vendor.package_kms.toLocaleString() + ' Kms') : props.myChoizeKms}      
                            </p> 
                        </div>                
                        </div>
                        <div className={css`${tw`-ml-5 w-full z-100 h-px bg-grey absolute self-center justify-end align-center`}`}/>
                        </div>
                        )
                        }
                        if(props.isFuel && vendor.measure_cd.includes('with fuel'))
                        {
                        return(
                        <div>
                        <div onClick={()=>{props.onClick(vendor)}} className={css`${tw`w-full flex flex-row items-center justify-between cursor-pointer`}`}>
                        <div className={css`${tw`flex flex-row items-center justify-between text-left mt-1 mx-8 py-6 px-5`}`}>
                                        <div className={css`${tw`h-8 mt-2`}`}
                                            style={{
                                                width: '4rem', 
                                                backgroundImage: `url(${vendor.image_url})`,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                            }}>

                                        </div>
                                        
                                        <div className={css`${tw`h-8 w-px bg-grey mx-2`}`}/>
                                        
                                        <p className={css`${tw`w-1/3 text-sm text-black font-medium`}; left: 10%`}>
                                            <span className={css`${tw`inline-flex`}`}><img src={require('../SvgComponents/starLabelSvg.svg')} alt="rating" /></span>
                                            {vendor.rating}
                                        </p>
                                    </div>

                        <div className={css`${tw`w-auto h-auto px-4 mx-2`}`}>
                            
                            <div className={css`${tw`flex flex-row items-center justify-evenly pt-2 `}`}>
                                <p className={css`${tw`font-bold text-xl tracking-tight`}`}>
                                    &#8377; {Math.round(vendor.discountedValue).toLocaleString()}
                                </p>
                                {vendor.couponValue > 0 && vendor.couponValue !== null &&
                                <div className={css`${tw`flex items-center ml-2`}`}>
                                    <img className={css`${tw`inline-flex`}`} src={require('../SvgComponents/couponLabelSvg.svg')} alt="Zyppys" />
                                    { /* <p className={cardStyles.couponText}>Best Coupon Applied</p> */}
                                </div>
                                }
                            </div>
                            <p className={css`${tw`font-light opacity-50 text-sm tracking-tight uppercase`}`}>
                                For {vendor.hasOwnProperty('package_kms') ? vendor.package_kms.toLocaleString() : props.myChoizeKms}      
                            </p> 
                        </div>                
                        </div>
                        <div className={css`${tw`-ml-5 w-full z-100 h-px bg-grey absolute self-center justify-end align-center`}`}/>
                        </div>
                        )
                        }
                        }
                    })

                }
                </GradientScroll>
            </animated.div>
            }
        </Spring>

        </div>
    );
}

const popupStyles ={
    container: css`
        ${'' /* box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2); */}
        border-radius: 20px;
        ${tw`flex z-300 flex-wrap py-5 px-5 overflow-auto`};
        position: absolute;
        height:90%;
        width:100%;
        left:0;
        right:10%;
        top:2%;
        @media (min-width: 992px){
            height:94%;
            width:45%;
            left:30%;
            right:30%;
            top:2%;
        }
        ::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

    `,
     firstBox: css`
        background: #FFFFFF;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        ${tw`flex z-400 flex-wrap py-4 `};
        width:100%;
        ::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

    `,
    secondBox:css`
        flex-direction:column;
        height:42%;
        width:100%;
        background-color:#F2F2F2;
        ${tw`z-300 py-5 overflow-y-scroll`};
        margin-top:-1.25rem;
        border-radius: 0px 0px 20px 20px;
        @media (min-width: 992px){
            height:45%;
        }
        ::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

    `,
    lastBox:css`
        ${'' /* height:15%;
        width:20%; */}
        background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%);
        ${'' /* border-radius: 20px 20px 20px 20px; */}
        ${'' /* text-align:center; */}
        ${'' /* flex-direction:column; */}
        ${'' /* ${tw`z-200 py-8 px-10`}; */}
        ${tw`mt-2 ml-4 w-auto z-200 flex flex-row align-center justify-center border-solid border-2 rounded-full px-6 py-2 cursor-pointer`};
        color:#FFFFFF;
        font-family: 'proxima-nova', ProximaNova-Bold; 
        letter-spacing: 0.8px;
        cursor:pointer;
    `,
    carImage:css`
    height: 4rem;
    @media (min-width: 992px){
        height: 8rem;
    }
    `,
}

const cardStyles = {
    CarCard: css`
        ${tw`relative flex flex-wrap items-center z-0 my-6 lg:mx-4 px-6 py-6`};
        min-height: 22rem;
        height: auto;
        border-radius: 16px;
        background: #f8f8fb;
        transition: all 0.25s ease-in-out;
        width: 100%;
        box-sizing: border-box;
        @media (min-width: 992px){
            min-height: 22rem;
            height: auto;
            width: 30%;
            &:hover{
                cursor: pointer;
                background: white;
                transform: translate3d(0,0,5px) scale(1.01);
                box-shadow: 0 15px 30px -10px rgba(0,0,0,0.2);
            }
        }
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
        }

        ${'' /* to remove the lnk colors from the cards and all 'a' links */}
        a:-webkit-any-link {
            color: inherit !important;
        }

    `,
    carImage: css`
        height: 8.5rem;
        @media(min-width: 992px){
            // transform: scale(1.1);
        }
    `,
    carName: css`
        ${tw`ml-0 mb-0`};
        opacity: 0.9;
        color: #222222;
        letter-spacing: -0.73px;
        font-size: 18px;
        @media(min-width: 992px){
            font-size: 20px;
        
        }
    `,
    reviewText: css`
        ${tw`block text-xl`};
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;
        margin-left:5px;
        vertical-align: middle;
        ${'' /* &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        } */}
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    carSpecs: css`
        opacity: 0.5;
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;
        margin-left:5px;
        vertical-align: middle;
        ${'' /* &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        } */}
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    couponText: css`
        ${tw`ml-2 my-2 opacity-40`};
        opacity: 0.4; 
        font-family: 'proxima-nova', ProximaNova-Regular; 
        letter-spacing: -0.23px; 
        font-size: 0.6rem;
        @media (min-width: 992px){
            font-size: 0.6rem;
        }
    
    `,
    serviceText: css`
        ${tw`w-10 text-right`};
        font-family: 'proxima-nova', ProximaNova-Regular; 
        font-size: 0.6rem;
        font-style: normal;
        ${'' /* font-weight: 500; */}
        font-size: 12px;
        line-height: 13px;
        letter-spacing: -0.4px;
        color: #414242;
        mix-blend-mode: normal;
        opacity: 0.5;
        margin-right:1rem;
        ${'' /* margin-left:4rem; */}
    `,
    providedText:css`
    font-family: 'proxima-nova', ProximaNova-Regular; 
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.4px;

    color: #414242;

    mix-blend-mode: normal;
    opacity: 0.5;
    `,
    lastContainer:css`
    ${tw`flex flex-row items-center justify-between text-right`};
    ${'' /* left:50%; */}
    position: absolute;
    right:10%;
    cursor:pointer;

    `,
}

export default CarPopup;