import axios from 'axios';
import { urls as urls } from '../api/urls';

export function updateTrips(data) {
    return {
        type: 'UPDATE_TRIPS',
        data: data
    }
}

export function flushTripState(){
    return {
        type: 'FLUSH_TRIP_STATE'
    }
}

export function tripsAvailableFalse() {
    return {
        type: 'TRIPS_AVAILABLE_FALSE'
    }
}

export function tripsAvailableTrue() {
    return {
        type: 'TRIPS_AVAILABLE_TRUE'
    }
}

export function tripsLoadingFalse() {
    return {
        type: 'TRIPS_LOADING_FALSE'
    }
}

export function tripsLoadingTrue() {
    return {
        type: 'TRIPS_LOADING_TRUE'
    }
}


export function fetchTrips() {
    return function (dispatch, getState) {
        
        dispatch(tripsLoadingTrue());
        let url = urls.fetchTripsUrl;
        let state = getState();
        let phone = getState().user.phone
        let data = {
            "mobileNo": phone
        }
        axios.post(url, data)
            .then(response => {
                dispatch(tripsLoadingFalse());
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    dispatch(updateTrips(response.data.responseData));
                    dispatch(tripsAvailableTrue());
                }
                else{
                    dispatch(updateTrips([]));
                    dispatch(tripsAvailableFalse());
                }
            })
            .catch(err => {
                dispatch(tripsLoadingFalse());
                dispatch(updateTrips([]));
                dispatch(tripsAvailableFalse());
            })
    }
}




export function fetchTripById(tripId, success, cancel){
    return function (dispatch,getState){
        let url = urls.fetchTripsUrl;
        let data = {
            "bidId": tripId
        }
        axios.post(url,data)
            .then(response => {
                if(response.data.statusCD === 200){
                    success(response.data.responseData[0]);
                }
            })
    }
}




export function fetchTripStatus(tripId, success, cancel){
    return function (dispatch,getState){
        let url = urls.tripStatusUrl;
        let data = {
            "bidId": tripId
        }
        axios.post(url,data)
            .then(response => {
                if(response.data.statusCD === 200){
                    success(response.data.responseData[0]);
                }
            })
    }
}
