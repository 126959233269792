import React from 'react';
import { css } from 'emotion';
import {Spring, interpolate, animated, config} from 'react-spring/renderprops';


/*global tw*/

class PaymentRadio extends React.Component{
    constructor(props){
        super(props);
        this.checkbox = React.createRef();
    }
    render(){
        return(
            <div onClick={this.props.onClick} className={css`${tw`flex w-full items-center my-2 py-2 cursor-pointer`}`}>
                <Spring 
                    from={{
                        bgAlpha: 0,
                        borderColor: "rgba(50,200,10, 1)",
                    }}
                    to={{ 
                        bgAlpha: this.props.active ? 1 : 0,
                        borderColor: this.props.active ? 'rgba(50, 200, 10, 1)' : "#222222",
                    }}
                    config={config.wobbly}
                    clamp={true}>
                    {values =>
                        <animated.div
                            className={css`${tw`inline-flex self-center rounded-full}`}`}
                            style ={{
                                height: '30px',
                                width: '30px',
                                background: `rgba(50,200,10, ${values.bgAlpha}`,
                                border: `2px solid  ${values.borderColor}`,
                                borderRadius: '100px'

                            }} 
                            ref={this.checkBox}>
                            <svg width="33" height="24" style={{transform: 'scale(0.6)'}} xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.578.594a2 2 0 0 1 2.844 2.812L13.172 22.87a2 2 0 0 1-2.746.093L.85 14.499A2 2 0 1 1 3.5 11.5l8.159 7.212L29.578.593z" fill="#FFF" fill-rule="nonzero"/>
                            </svg>
                        </animated.div>
                    }
                </Spring>

                {this.props.children}
            </div>
        );
    }
}

export default PaymentRadio;