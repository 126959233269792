import React, {Component} from 'react';
import {css} from 'emotion';
import { Spring, config, animated, interpolate } from 'react-spring/renderprops';

import '../appComponents/accordion-styles.css';

const styles ={
    title: css`
        ${tw`w-full flex items-center py-6 px-6 cursor-pointer font-medium justify-between`};
        opacity: 0.65;
        font-size: 1rem;
        @media(min-width: 992px){
            font-size: 22.65px;
        }
        color: #222222;
        letter-spacing: -0.4px;
        &:nth-of-type(even){
            ${tw`bg-white`};
            background: white !important;
        }
    `,
}
/* global tw*/
class AccordionItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            isActive: this.props.type === 'vendors' ? false : false
        }
    }
    render(){
        return(
            <div className={css`${tw`flex w-full flex-wrap`}` + ' accordion-item' }>
                <div onClick={()=> {        
                    // eslint-disable-next-line
                    analytics.track("Accordion Item Clicked",{category:'clicks',label:this.props.title});
                    this.setState({isActive: !this.state.isActive})}}
                    className={styles.title + ' accordion-title'}>
                    <p>{this.props.title}</p>
                    <Spring from={{ r: 0 }} 
                            to={{ r: this.state.isActive ? -180 : 0}}
                            native>
                        {({r})=>
                            <animated.div style={{transform: r.interpolate(r => `rotateX(${r}deg)`)}}>
                                <img className={css`${tw`h-3`}`} src={require('../SvgComponents/angle-down.svg')}/>
                            </animated.div>
                        }
                    </Spring>
                </div>
                <Spring 
                        native
                        from={{height: 0, opacity: 0}} 
                        to={{ display: this.state.isActive ? 'flex' : 'none', height: this.state.isActive ? 'auto': 0, opacity: this.state.isActive? 1:0, zIndex: this.state.isActive? 0:0}}
                        config={config.stiff}
                        clamp>
                    {values =>
                        <animated.div style={values} className={css`${tw`flex-wrap w-full`}`}>
                            {this.props.children}
                        </animated.div>
                    }
                </Spring>
            </div>
        );
    }
}


export default AccordionItem;

AccordionItem.defaultProps = {
    title: 'Default Title'
}