import React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { css } from 'emotion';
import '../../assets/fonts/fonts.css';

/* global tw */
const sliderStyle = {  // Give the slider some width
    position: 'relative',
    // width: '50%',
    height: 80,
    // border: '1px solid steelblue',
  }
  
  const railStyle = { 
    position: 'absolute',
    width: '100%',
    height: 10,
    marginTop: 35,
    borderRadius: 5,
    backgroundColor: '#C8C8C8',
  }

  const Handle = ({ // your handle component
    handle: { id, value, percent }, 
    getHandleProps
  }) => {
    return (
        <div
          style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: -15,
            marginTop: 25,
            zIndex: 2,
            width: 30,
            height: 30,
            border: 0,
            textAlign: 'center',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: '#7d0ede',
            color: '#333',
          }}
          {...getHandleProps(id)}
        >
         {id === '$$-0' && <div 
          style={{ fontSize: 11, marginTop: 50 }}
          >
           &#8377;{value.toLocaleString()}
          </div>}
          {id === '$$-1' && <div 
          style={{ fontSize: 11, marginTop: -30 }}
          >
           &#8377;{value.toLocaleString()}
          </div>}
        </div>
      )
  }

  const  Track = ({ source, target, getTrackProps }) => { // your own track component
    return (
      <div
        style={{
          position: 'absolute',
          height: 10,
          zIndex: 1,
          marginTop: 35,
          backgroundColor: '#7d0ede',
          borderRadius: 5,
          cursor: 'pointer',
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
      />
    )
  }

 const Tick = ({ tick, count }) => {  // your own tick component
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            marginTop: 52,
            marginLeft: -0.5,
            width: 1,
            height: 8,
            backgroundColor: 'silver',
            left: `${tick.percent}%`,
          }}
        />
        <div
          style={{
            position: 'absolute',
            marginTop: 60,
            fontSize: 10,
            textAlign: 'center',
            marginLeft: `${-(100 / count) / 2}%`,
            width: `${100 / count}%`,
            left: `${tick.percent}%`,
          }}
        >
          &#8377;{(tick.value).toLocaleString()}
        </div>
      </div>
    )
  }

const SliderComp = (props) =>{
    return (
        <Slider
        className={css`${tw`w-full mt-2`}`}
    rootStyle={sliderStyle} // inline styles for the outer div. Can also use className prop.
    domain={[props.minAmt, props.maxAmt]}
    step={500}
    mode={2}
    values={[props.minAmt, props.maxAmt]}  // two values = two handles
    onChange={(event)=>{props.budgetValues(event)}}
 >
     <Rail>
      {({ getRailProps }) => (  // adding the rail props sets up events on the rail
        <div style={railStyle} {...getRailProps()} /> 
      )}
    </Rail>
    <Handles>
      {({ handles, getHandleProps }) => (
        <div className="slider-handles">
          {handles.map(handle => (
            <Handle
              key={handle.id}
              handle={handle}
              getHandleProps={getHandleProps}
            />
          ))}
        </div>
      )}
    </Handles>
    <Tracks left={false} right={false}>
      {({ tracks, getTrackProps }) => (
        <div className="slider-tracks">
          {tracks.map(({ id, source, target }) => (
            <Track
              key={id}
              source={source}
              target={target}
              getTrackProps={getTrackProps}
            />
          ))}
        </div>
      )}
    </Tracks>
    {/* <Ticks count={3}>
      {({ ticks }) => (
        <div className="slider-ticks">
          {ticks.map(tick => (
            <Tick key={tick.id} tick={tick} count={ticks.length} />
          ))}
        </div>
      )}
    </Ticks> */}
</Slider>
    )
}

export default SliderComp;